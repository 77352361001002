import type { Transition, UIRouter } from '@wix/tpa-router';
import { SeoTagsPayload } from '@wix/native-components-infra/dist/src/types/types';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

/**
 * SEO helper tool
 * will call `resolver` with all resolvables for current route
 * so you can use them to generate SEO tags
 */
export function addSeoTagsProvider(
  router: UIRouter,
  params: ControllerParams,
  resolver: (
    transition: Transition,
  ) => Promise<SeoTagsPayload> | SeoTagsPayload,
) {
  return router.transitionService.onCreate({}, handleTransitionCreate);

  function handleTransitionCreate(transition: Transition) {
    const { controllerConfig, flowAPI } = params;

    transition.addResolvable(
      {
        token: 'SEO',
        deps: transition.getResolveTokens(),
        async resolveFn() {
          try {
            const result = await resolver(transition);

            controllerConfig.wixCodeApi.seo.renderSEOTags(result);

            return result;
          } catch (err) {
            const error = err as Error;
            flowAPI.errorMonitor.captureException(error as Error);
            console.error(error);
          }
        },
      },
      transition.to(),
    );
  }
}
